// @ts-nocheck
export const initFreshdesk = () => {
  if (!_inIframe()) {
    // Freshdesk Widget Code
    window.fwSettings = { widget_id: 11000000060 };
    // eslint-disable-next-line
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        // eslint-disable-next-line no-unused-expressions
        (n.q = []), (window.FreshworksWidget = n); //eslint-disable-line
      }
    })();

    const freshdeskScript = document.createElement('script');
    freshdeskScript.type = 'text/javascript';
    freshdeskScript.src =
      'https://widget.freshworks.com/widgets/11000000060.js';
    freshdeskScript.setAttribute('async', true);
    freshdeskScript.setAttribute('defer', true);
    document.head.appendChild(freshdeskScript);
  }

  function _inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
};
