/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

import React, { useEffect, useState } from 'react';
import { useHydrated } from 'remix-utils/use-hydrated';
import allowRendering from '~/utils/allowRendering.client';
import { cssBundleHref } from '@remix-run/css-bundle';
import { json, LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import tailwindcssUrl from './tailwind.css';
import bootstrapStylesUrl from 'bootstrap/dist/css/bootstrap.css';
import exaptiveStylesUrl from '@exaptive/styleguide/build/release/complete.css';
import { initFreshdesk } from './utils/freshdesk';
import { getSubdomain, isJwtExpired } from './utils/web';
import { getUserSession } from './utils/auth.server';
import {
  cnapi2GetRequest,
  getCity,
  getCityConfig,
  getCnapiHost,
  getCognitiveCityFrontendConfig,
  getElement,
  getElementTypesWithResourceConfigs,
  getHostCity,
  getUserAppData } from
'./utils/cnapi2';
import { getEnv } from './utils/env.server';
import { useNonce } from './utils/nonce-provider';
import { withSentry } from '@sentry/remix';
import { makeTimings, time } from './utils/timing.server';
import "@radix-ui/themes/styles.css?__remix_sideEffect__";

// eslint-disable-next-line
export const links = () => [
{ rel: 'icon', href: '/favicon.ico' },
{ rel: 'manifest', href: '/manifest.json' },
{
  rel: 'stylesheet',
  href: 'https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css'
},
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700'
},
{
  rel: 'stylesheet',
  href: 'https://unpkg.com/@progress/kendo-theme-bootstrap@7.0.1/dist/all.css'
},
{
  rel: 'stylesheet',
  href: bootstrapStylesUrl
},
{
  rel: 'stylesheet',
  href: tailwindcssUrl
},
{
  rel: 'stylesheet',
  href: exaptiveStylesUrl
},
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


export const meta: MetaFunction = () => [
{
  title: 'Exaptive'
},
{
  name: 'description',
  content: 'The Cognitive City'
}];


export function shouldRevalidate({
  formAction,
  currentParams,
  defaultShouldRevalidate




}: {formAction: string;currentParams: Record<string, string>;defaultShouldRevalidate: boolean;}) {
  if (formAction === `/cognitive/view/${currentParams.viewUuid}`) {
    return false;
  }
  return defaultShouldRevalidate;
}

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const subdomain = getSubdomain(request);
  const user = await getUserSession(request);
  const cityInfo = await getCity(subdomain);
  const userElement = user?.access_token ?
  await getElement(subdomain, user?.access_token, {
    type: cityInfo.userType,
    'aux.user': user?.uuid
  }) :
  null;

  const cnapiHost = await getCnapiHost(subdomain);
  if (!user || isJwtExpired(user?.access_token)) {
    return json({
      isAuthenticated: false,
      isCityUser: false,
      token: null,
      env: getEnv()
    });
  }

  const [
  city,
  cityConfig,
  cognitiveCityConfig,
  legacyViews,
  exploratoryViews,
  tools,
  elementTypesWithConfigs] =
  await Promise.all([
  time(() => getHostCity(cnapiHost), { timings, type: 'get host city' }),
  time(() => getCityConfig(cnapiHost), {
    timings,
    type: 'get city config'
  }),
  time(() => getCognitiveCityFrontendConfig(cnapiHost), {
    timings,
    type: 'get cognitive city config'
  }),
  time(
    () =>
    cnapi2GetRequest(
      `${cnapiHost}/cnapi/view?cityNamespace=main`,
      user.access_token
    ),
    {
      timings,
      type: 'get legacy views'
    }
  ),
  time(
    () =>
    cnapi2GetRequest(
      `${cnapiHost}/cnapi/exploratory-view?cityNamespace=main`,
      user.access_token
    ),
    { timings, type: 'get exploratory views' }
  ),
  time(
    () =>
    cnapi2GetRequest(
      `${cnapiHost}/cnapi/app/tool?cityNamespace=main`,
      user.access_token
    ),
    { timings, type: 'get tools' }
  ),
  time(
    () => getElementTypesWithResourceConfigs(subdomain, user?.access_token),
    {
      timings,
      type: 'get element types with configs'
    }
  )]
  );

  const userAppData = await getUserAppData(
    cnapiHost,
    city.uuid,
    user.uuid,
    user.access_token
  );

  const data = {
    env: getEnv(),
    userAuthContext: {
      isAuthenticated: !!user && !isJwtExpired(user?.access_token),
      user: {
        ...user,
        userElement
      }
    },
    isAdmin: user.isCityAdmin || user.isSystemAdmin,
    isCityUser: !!user?.isCityUser,
    cityConfig,
    cognitiveCityConfig,
    legacyViews: legacyViews.data,
    exploratoryViews: exploratoryViews.data,
    tools: tools.data,
    userElement,
    user,
    cityColors: cityConfig.style.colors,
    elementTypesWithConfigs,
    userAppData,
    token: user.access_token
  };
  return json<typeof data>(data, {
    headers: { 'Server-Timing': timings.toString() }
  });
}

function App(): JSX.Element {
  const [allowRender, setAllowRender] = useState(true);
  const isHydrated = useHydrated();
  const nonce = useNonce();
  const data = useLoaderData<typeof loader>();
  useEffect(() => {
    if (!isHydrated) return;

    if (!allowRendering()) {
      setAllowRender(false);
    }
  }, [isHydrated, setAllowRender]);

  return <Document nonce={nonce} env={data?.env} allowRender={allowRender} />;
}

function Document({
  nonce,
  env = {},
  allowRender




}: {nonce: string;env?: Record<string, string>;allowRender: boolean;}) {
  initFreshdesk();
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <div id="root">
          {allowRender ?
          <>
              <Outlet />
              <ScrollRestoration nonce={nonce} />
              <Scripts nonce={nonce} />
              <LiveReload nonce={nonce} />
            </> :

          <>Cannot render in iframe</>}

        </div>
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

      </body>
    </html>);

}

export default withSentry(App);