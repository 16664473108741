import DOMPurify from 'dompurify';

export const whitelist = [
  // Origin added to allow a page to iframe itself
  window.self.origin,
  'https://holaamerica.org',
  'https://travesia100.cl',
  'https://holacolombiainnpacta.com',
  'https://embedded-apps.cognitive.city',
  /^https:\/\/[a-z0-9-_]+\.cognitive\.city$/,
  /^https:\/\/[a-z0-9-_]+\.exaptive\.dev$/,
  'https://www.oshercollaborative.org',
  'https://oshercenter.org',
  'https://okcinnovation.com',
  'https://www.feinberg.northwestern.edu',
  'https://careernavigator.gradeducation.hms.harvard.edu',
  'https://raremd.com',
  'https://preview-lf246gy-w4iunvpugvdha.fr-3.platformsh.site',
  'https://resource-hub.eit-hei.eu',
];

function allowRendering(): boolean {
  return window.self === window.top || _allowedReferrer();
}

function _allowedReferrer() {
  const iframeTopOrigin = _getOrigin(document.referrer);
  for (let i = 0; i < whitelist.length; ++i) {
    const whiteListOrigin = whitelist[i];
    if (_matchDomains(iframeTopOrigin, whiteListOrigin)) {
      return true;
    }
  }

  return false;
}

function _getOrigin(path: string) {
  const temp = document.createElement('a');
  temp.href = DOMPurify.sanitize(path);
  return temp.origin;
}

function _matchDomains(domain: string, matcher: string | RegExp) {
  if (typeof matcher === 'string') {
    return domain === matcher;
  }
  return matcher.test(domain);
}

export default allowRendering;
